import React from "react"
import { Router } from "@reach/router"

import PrivateRoute from "../../components/privateRoute";
import Login from "../login";
import Register from "../register";
import Files from "./files";
import Home from "./home";

const App = () => (
    <Router>
        <Login path="/login" />
        <Register path="/register" />
        {/* <Files path="/app/files" /> */}
        <PrivateRoute path="/app/files" component={Files} />
        <PrivateRoute path="/app/home" component={Home} />
    </Router>
)

export default App