import React from "react";

import { navigate, Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";

import { useForm } from "react-hook-form";
import { loginUser, isLoggedIn } from "../helpers/user";


function Login() {
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        await loginUser(data).then(() => {
            if (isLoggedIn()) {
                navigate("/app/files")
            }
        })
    }

    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title="Home"
            />

            <section className="h-[90vh] py-6 flex flex-col justify-center sm:py-12">

                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    <div className="relative h-full w-full">
                        <div className="card bg-tertiary shadow-lg  w-full h-full rounded-3xl absolute  transform -rotate-6"></div>
                        <div className="card bg-secondary shadow-lg  w-full h-full rounded-3xl absolute  transform rotate-6"></div>
                        <div className="relative w-full rounded-3xl  px-6 py-4 bg-primary shadow-md">
                            <label htmlFor="" className="block mt-3 text-bg text-center">
                                Login
                            </label>
                            <form method="#" action="#" className="mt-10" onSubmit={handleSubmit(onSubmit)} >
                                <div>
                                    <input {...register("email")} type="email" placeholder="Email" className="text-primary mt-1 block w-full border-none bg-gray-100 h-11 rounded-md shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0" />
                                </div>

                                <div className="mt-6">
                                    <input {...register("password")} type="password" placeholder="Password" className="text-primary  mt-1 block w-full border-none bg-gray-100 h-11 rounded-md shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0" />
                                </div>

                                <div className="mt-7 flex">
                                    <label htmlFor="remember_me" className="inline-flex items-center w-full cursor-pointer">
                                        <input id="remember_me" type="checkbox" className="rounded border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" name="remember" />
                                        <span className="ml-2 text-sm text-gray-600">
                                            Remember me
                                        </span>
                                    </label>
                                </div>

                                <div className="mt-7">
                                    <button className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                        Login
                                    </button>
                                </div>

                                <div className="flex mt-7 items-center text-center">
                                    <hr className="border-gray-300 border-1 w-full rounded-md" />
                                    <label className="block font-medium text-sm w-full">
                                        Login with
                                    </label>
                                    <hr className="border-gray-300 border-1 w-full rounded-md" />
                                </div>

                                <div className="flex mt-7 justify-center w-full">
                                    <button className="mr-5 bg-blue-500 border-none px-4 py-2 rounded-xl cursor-pointer text-white shadow-xl hover:shadow-inner transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">

                                        Facebook
                                    </button>

                                    <button className="bg-red-500 border-none px-4 py-2 rounded-xl cursor-pointer text-white shadow-xl hover:shadow-inner transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">

                                        Google
                                    </button>
                                </div>

                                <div className="mt-7">
                                    <div className="flex justify-center items-center">
                                        <label className="mr-2 " >Do you need to create an account?</label>

                                        <Link to={"/app/register"}>
                                        <button className=" text-blue-500 transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                            Register
                                        </button>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default Login;