import React from "react";
import { useForm } from "react-hook-form";

import { navigate, Link } from "gatsby"
import { registerUser } from "../helpers/user"
import Layout from "../components/layout";
import SEO from "../components/seo";

function Register() {
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        const user = await registerUser(data)
        //check if user is not undifined
        if (user !== undefined && user !== null) {
            navigate("/login/")
        }
    }

    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title="Home"
            />

            <section className="h-[90vh] py-6 flex flex-col justify-center sm:py-12">

                <div className="relative py-3 sm:max-w-xl sm:mx-auto ">
                    <div className="relative h-full w-full">
                        <div className="card bg-tertiary shadow-lg  w-full h-full rounded-3xl absolute  transform -rotate-6"></div>
                        <div className="card bg-secondary shadow-lg  w-full h-full rounded-3xl absolute  transform rotate-6"></div>
                        <div className="relative w-full rounded-3xl  px-6 py-4 shadow-md bg-primary">
                            <label htmlFor="" className="block mt-3 text-sm text-center font-semibold">
                                Register
                            </label>
                            <form method="#" action="#" className="mt-10" onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <input {...register("email")} type="email" placeholder="Email" className="text-gray-500 mt-1 block w-full border-none bg-gray-100 h-11 rounded-md shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0" />
                                </div>

                                <div className="mt-6">
                                    <input {...register("password1")} type="password" placeholder="Password" className="text-gray-500 mt-1 block w-full border-none bg-gray-100 h-11 rounded-md shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0" />
                                </div>

                                <div className="mt-6">

                                    <input
                                        type="password" placeholder="Repeat Password" className="text-gray-500 mt-1 block w-full border-none bg-gray-100 h-11 rounded-md shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0" />
                                </div>

                                <div className="mt-7">
                                    <button className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                        Register
                                    </button>
                                </div>

                                <div className="flex mt-6 items-center text-center">
                                    <label className="block font-medium text-sm text-gray-600 w-full">
                                        Register with
                                    </label>
                                </div>

                                <div className="flex mt-6 justify-center w-full disabled">
                                    <button className="mr-5 bg-blue-500 border-none px-4 py-2 rounded-xl cursor-pointer text-white shadow-xl hover:shadow-inner transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                        Facebook
                                    </button>

                                    <button className="disabled bg-red-500 border-none px-4 py-2 rounded-xl cursor-pointer text-white shadow-xl hover:shadow-inner transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                        Google
                                    </button>
                                </div>

                                <div className="mt-7">
                                    <div className="flex justify-center items-center">
                                        <label className="mr-2 " >Do you already have an account?</label>
                                        <Link to={"/login"}>
                                        <button href="login" className=" text-blue-500 transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                            Login
                                        </button>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default Register;